<template>
    <slot name="activator" :onClick="onClick" :isActive="isActive" :a11yAttributes="a11yAttributes"></slot>
    <div ref="collapseElRef" :id="id" class="collapse" :class="collapseClass" v-on="events">
        <slot :hide="hide"></slot>
    </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Collapse from 'bootstrap/js/dist/collapse';

const props = defineProps({
    value: Boolean,
    id: String,
    collapseClass: String
})

const emit = defineEmits(['show', 'shown', 'hide', 'hidden']);


const collapseElRef = ref<HTMLElement|null>(null);
let collapseInstance: Collapse;

const isActive = ref(false);
const isAnimationInProgress = ref(false);


const onClick = (e: Event) => {
    e.preventDefault()
    if (collapseInstance) {
        collapseInstance.toggle()
    }
}

const hide = () => collapseInstance.hide();

defineExpose({ hide });

onMounted(() => {
    const collapseEl = collapseElRef.value;
    if (!collapseEl) return;

    collapseInstance = Collapse.getOrCreateInstance(collapseEl, { toggle: false });
    if (props.value) {
        collapseInstance.show();
    }
})

onBeforeUnmount(() => {
    if (collapseInstance) {
        collapseInstance.dispose()
    }
})

const events = {
    'show.bs.collapse': () => {
        isAnimationInProgress.value = true;
        isActive.value = true;
        emit('show')
    },
    'shown.bs.collapse': () => {
        isAnimationInProgress.value = false;
        emit('shown')
    },
    'hide.bs.collapse': () => {
        isAnimationInProgress.value = true;
        isActive.value = false;
        emit('hide')
    },
    'hidden.bs.collapse': () => {
        isAnimationInProgress.value = false;
        emit('hidden')
    }
}

const a11yAttributes = computed<any>(() => ({
    role: 'button',
    'aria-expanded': isActive.value ? 'true' : 'false',
    'aria-controls': props.id
}))

</script>